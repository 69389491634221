/* eslint-disable
    import/no-webpack-loader-syntax,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
require('script-loader!jstz/dist/jstz')
require('bootstrap/bootstrap-carousel')
require('script-loader!etc/login')
require('script-loader!jquery/jquery.validate')

// jQuery Validate custom methods
require('initializers/validator')
require('expose-loader?exposes=Password!modules/validPassword')
